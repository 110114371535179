<template>
  <div
    class="DestinationsList"
    ref="destinationsRef"
  >
    <div
      v-show="isSmallScreen && openState"
      class="menuItem"
      @click="backOneStep"
    >
      <q-icon name="icon-chevron-left" />
      Terug
    </div>

    <div v-if="isSmallScreen && !openState">
      <LayoutDestinationItem
        text="Bestemmingen"
        :hasSubItems="true"
        @click="openState = !openState"
        :active="false"
      />
    </div>

    <div v-show="showRowOne">
      <div class="category">Bestemmingen</div>
      <LayoutDestinationItem
        v-for="subItem in destinations"
        :key="subItem.name"
        :text="subItem.name"
        :hasSubItems="true"
        :active="selectedItem1Id === subItem.id"
        @click="selectedItem1Id = subItem.id"
      />
    </div>

    <div
      v-for="group in destinationsLevel2"
      :key="group.id"
      :style="{
        display: showRowTwo && group.id === selectedItem1Id ? 'flex' : 'none',
      }"
      class="column"
      :class="{ hideItems }"
    >
      <div class="category">{{ item1Name }}</div>
      <LayoutDestinationItem
        v-for="(subItem, idx) in group.children"
        :key="subItem.name"
        :text="subItem.name"
        :hasSubItems="subItem.children?.length > 0"
        :active="selectedItem2Id == subItem.id"
        @click="selectedItem2Id = subItem.id"
      />

      <div
        v-if="hideItems && hasEnoughItems"
        @click="hideItems = !hideItems"
        class="category"
        id="showAllButton"
      >
        Bekijk alle {{ item1Name?.toLowerCase() }}
      </div>
    </div>

    <div
      class="column"
      :style="{ display: showRowThree ? 'flex' : 'none' }"
    >
      <div class="category">{{ item2Name }}</div>
      <LayoutDestinationItem
        v-for="subItem in destinationsLevel3"
        :key="subItem.id"
        :text="subItem.name"
        :hasSubItems="!!subItem.children.length"
        :active="false"
        :link="subItem.url"
        :style="{ display: selectedItem2Id == subItem.parentId ? 'flex' : 'none' }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useElocusStore from '@/store/elocus';
import { Screen } from 'quasar';

const emit = defineEmits(['open-state', 'clicked-outside']);
const elocusStore = useElocusStore();
const router = useRouter();

const hideItems = ref(true);
const destinationsRef = ref();
const destinations = computed(() => elocusStore.destinations);

const openState = ref(false);
const selectedItem1Id = ref(0);
const selectedItem2Id = ref(0);

const item1Name = computed(() => (selectedItem1Id.value ? destinations.value.find((item) => item.id == selectedItem1Id.value)?.name : ''));
const item2Name = computed(() =>
  selectedItem2Id.value
    ? destinations.value.find((item) => item.id == selectedItem1Id.value)?.children.find((child) => child.id == selectedItem2Id.value)?.name
    : ''
);
const hasEnoughItems = computed(() => {
  if (!selectedItem1Id.value) return false;

  const find = destinations.value.find((item) => item.id == selectedItem1Id.value);
  return find?.children ? find?.children?.length > 5 : false;
});

const { destinationsLevel2, destinationsLevel3 } = menuItemsHandler();
const { showRowOne, showRowTwo, showRowThree, backOneStep, isSmallScreen } = mobileHandling();

onMounted(() => {
  if (!isSmallScreen.value) {
    window.addEventListener('click', (event) => {
      if (!destinationsRef.value?.contains(event.target) && event.target?.id !== 'showAllButton') {
        emit('clicked-outside');
      }
    });
  }
});

router.beforeEach((to, from, next) => {
  selectedItem1Id.value = 0;
  selectedItem2Id.value = 0;
  emit('clicked-outside');
  next();
});

function menuItemsHandler() {
  const destinationsLevel2 = computed(() => destinations.value?.flatMap((item) => ({ id: item.id, children: item.children })));
  const destinationsLevel3 = computed(() => destinations.value?.flatMap((item) => item.children.flatMap((child) => child.children)));

  return {
    destinationsLevel2,
    destinationsLevel3,
  };
}

function mobileHandling() {
  const isSmallScreen = computed(() => Screen.width < 850);

  const showRowOne = computed(() => (isSmallScreen.value ? openState.value && selectedItem1Id.value == 0 && !selectedItem2Id.value : true));
  const showRowTwo = computed(() => (isSmallScreen.value ? selectedItem1Id.value != 0 && !selectedItem2Id.value : true));
  const showRowThree = computed(() => (isSmallScreen.value ? selectedItem2Id.value : true));

  function backOneStep() {
    if (selectedItem2Id.value) {
      selectedItem2Id.value = 0;
    } else if (selectedItem1Id.value) {
      selectedItem1Id.value = 0;
    } else {
      openState.value = false;
    }
  }

  watch(openState, (val) => {
    if (!val) {
      selectedItem1Id.value = 0;
      selectedItem2Id.value = 0;
    }
    emit('open-state', val);
  });

  watch(
    () => selectedItem1Id.value,
    (val) => {
      hideItems.value = true;
    }
  );

  watch(
    selectedItem1Id,
    (val) => {
      selectedItem2Id.value = 0;
    },
    { deep: true }
  );

  return {
    isSmallScreen,
    showRowOne,
    showRowTwo,
    showRowThree,
    backOneStep,
  };
}
</script>

<style lang="scss" scoped>
.DestinationsList {
  // width: 560px;
  background: white;
  border-radius: 24px;
  display: flex;
  gap: 20px;

  padding: 24px;
  box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(55, 55, 55, 0.2);

  .hideItems .DestinationItem:nth-child(n + 6) {
    display: none;
  }
}

.category {
  font-weight: bolder;
  padding: 12px 16px;
}

@media (max-width: 1000px) {
  .DestinationsList {
    padding: 0;
    box-shadow: none;
    flex-direction: column;
    font-size: 20px;
    display: block;
    width: 100%;
  }

  .menuItem {
    padding: 12px;
  }
}
</style>
