import { defineStore } from 'pinia';

export default defineStore('apiStore', () => {
    const apiBase = ref(import.meta.env.NUXT_ENV_API_BASE);

    function setApiBase(value: string) {
        apiBase.value = value;
    }


    return {
        apiBase,
        setApiBase,
    };
});
