<template>
  <DialogComponent title="Korting">
    <div
      class="col-12"
      v-for="(d, accoIdx) in activeBookingItems"
    >
      <div
        v-for="(unit, unitId) in d.units"
        class="q-mt-xl"
      >
        <BoekenInfoShoppingCartItemWideVariant
          :accommodation="activeBookingItems[accoIdx]"
          :unit="activeBookingItems[accoIdx].units[unitId]"
          class="q-my-lg"
        >
          <div class="q-mt-lg row q-gutter-x-md">
            <div>
              Korting op accommodatie
              <q-input
                outlined
                dense
                v-model.number="discountItems[accoIdx].discount"
                type="number"
              />
            </div>

            <div>
              Korting van Ecco Italia
              <q-input
                outlined
                dense
                v-model.number="discountItems[accoIdx].eccoDiscount"
                type="number"
              />
            </div>
          </div>
        </BoekenInfoShoppingCartItemWideVariant>
      </div>
    </div>

    <q-btn
      color="primary"
      @click="saveDiscount"
    >
      Korting opslaan
    </q-btn>
  </DialogComponent>
</template>

<script setup lang="ts">
import useBookingStore from '~/store/booking';

const emit = defineEmits(['close']);
const bookingStore = useBookingStore();
const activeBookingItems = computed(() => bookingStore.activeBookingItems);
const discountItems = ref(
  activeBookingItems.value.map((item) => ({
    id: item.id,
    index: item.index,
    discount: 0,
    eccoDiscount: 0,
  }))
);

function saveDiscount() {
  emit('close');
  bookingStore.saveDiscountPerAcco(discountItems.value);
}
</script>
