<template>
  <DialogComponent
    title="Prijs berekening"
    class="PriceCalculationModal"
  >
    <div
      v-html="prettyPrice"
      class="priceCalculation"
    />

    <q-btn
      color="primary"
      @click="emit('close')"
    >
      Sluiten
    </q-btn>
  </DialogComponent>
</template>

<script setup lang="ts">
import useBookingStore from '~/store/booking';

const emit = defineEmits(['close']);
const bookingStore = useBookingStore();
const price = computed<BookingPricing>(() => bookingStore.price);
const prettyPrice = computed<string>(() => syntaxHighlight());

function syntaxHighlight() {
  let json = JSON.stringify(price.value, undefined, 2);
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

  // Apply syntax highlighting
  return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, (match) => {
    let cls = 'value';
    if (/^"/.test(match)) {
      if (/:$/.test(match)) {
        cls = 'key';
      }
    }
    return `<span class="${cls}">${match}</span>`;
  });
}
</script>

<style scoped lang="scss">
.priceCalculation {
  font-family: monospace;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  white-space: break-spaces;
  min-width: 500px;
  max-width: 800px;

  &:deep() {
    .value {
      color: #606060;
    }
    .key {
      font-weight: bold;
    }

    .null {
      color: black;
    }
  }
}
</style>
